angular.module('eOpti.app.coupons').config(['$stateProvider', function ($stateProvider) {
    $stateProvider
        .state('app.coupons', {
            url: '/coupons',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.coupons.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'expiration_date',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/coupons/table.html',
            controller: ['breadcrumb', function (breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'salesList'
                }, {
                    functions: 'couponsList',
                    args: ['state']
                }]
            }]
        })
        .state('app.coupon', {
            url: '/coupon',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.coupon.details', {
            url: '/{id:[0-9]+}',
            template: '<opti-coupons-details></opti-coupons-details>',
            controller: ['$state', 'breadcrumb', function ($state, breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'salesList'
                }, {
                    functions: 'couponsList',
                    args: ['state']
                }, {
                    functions: 'coupon',
                    args: [$state.params.id, null]
                }]
            }]
        })
        .state('app.coupon.add', {
            url: '/add',
            template: '<opti-coupons-add></opti-coupons-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'salesList'
                }, {
                    functions: 'couponsList',
                    args: ['state']
                }, {
                    name: 'coupons.action.ADD'
                }]
            }]
        })
        .state('app.coupon.edit', {
            url: '/{id:[0-9]+}/edit',
            template: '<opti-coupons-edit></opti-coupons-edit>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'salesList'
                }, {
                    functions: 'couponsList',
                    args: ['state']
                }, {
                    functions: 'coupon',
                    args: [$state.params.id, 'state']
                }, {
                    name: 'coupons.action.EDIT'
                }]
            }]
        })
}])